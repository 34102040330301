.react-calendar {
    width: 368px;
    max-width: 100%;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 3px;
    
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
 
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    height: 56px;
    display: flex;
}

.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}

.react-calendar__navigation__label {
    order: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 24px;
}

.react-calendar__navigation__label:after {
    content: "";
    display: block;
    background-image: url('../../../Assets/Icons/ArrowDown.svg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 10px;
    height: 5px;
    position: relative;
    left: 15px;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #4d4d4d;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from::first-letter {
    text-transform: uppercase;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button {
    order: 2;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button svg {
    top: 0;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
    order: 3;
}

.react-calendar__navigation__arrow.react-calendar__navigation__next-button svg {
    top: 0;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
    display: none;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}

.react-calendar__navigation button[disabled] {
    background-color: #ffffff;
}

.react-calendar__month-view__weekdays {
    font-family: Inter;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;
    padding: 0 16px;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4D4D4D;
}

.react-calendar__month-view__weekNumbers {
    font-weight: bold;
}

.react-calendar__year-view__months{
    padding: 0.5em; 
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}

.react-calendar__month-view__days {
    padding: 10px 16px 16px;
}

.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
    visibility: hidden;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 0 0.5em;
}

.react-calendar__year-view .react-calendar__tile abbr,
.react-calendar__decade-view .react-calendar__tile abbr,
.react-calendar__century-view .react-calendar__tile abbr {
    width: unset;
}

.react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.2em 0.46em;
    background: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #17CF63;
}

.react-calendar__tile abbr {
    display: block;
    padding: 4px;
}

.react-calendar__tile:disabled {
    background-color: #ffffff;
    color: #F43E3E;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #ffffff;
}

.react-calendar__tile--now {
    background: #ffffff;

}

.react-calendar__tile--now abbr {
    border: 1px solid #666666 !important;
    border-radius: 16px;
    padding: 4px;
    display: block;
    height: 34px;
    width: 34px;
    margin: 0 auto;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #ffffff;
}

.react-calendar__tile--hasActive {
    background: #ffffff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #ffffff;
}

.react-calendar__tile--active {
    background: #ffffff;
}

.react-calendar__tile--rangeEnd {
    padding: 0;
}

.react-calendar__tile--active abbr, .react-calendar__tile--rangeEnd abbr {
    background: #17CF63;
    border: 1px solid #17CF63 !important;
    border-radius: 18px;
    padding: 4px;
    display: block;
    color: #ffffff;
    height: 34px;
    width: 34px;
    margin: 0 auto;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #ffffff;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
}

/** History filter calendar **/

.searchCalendar .react-calendar__tile--active.react-calendar__tile--range {
    padding: 0;
}

.searchCalendar .react-calendar__tile--active.react-calendar__tile--range abbr {
    border-radius: 0;
    margin: 0;
    width: 100%;
}

.searchCalendar .react-calendar__tile--active.react-calendar__tile--rangeStart abbr {
    border-radius: 16px 0 0 16px;
    margin: 0;
    width: 100%;
}

.searchCalendar .react-calendar__tile--rangeEnd abbr {
    border-radius: 0 16px 16px 0;
    margin: 0;
    width: 100%;
}

.searchCalendar .react-calendar__tile {
    color: #333333;
    height: 37px;
}

@media (max-width: 600px) {
    .react-calendar {
        width: 100%;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12);
        border-radius: 3px;
    }
    .searchCalendar .react-calendar__tile {
    font-size:12px;   
    }
    .react-calendar__tile {
       padding:0px;
       max-width: 100%;
       text-align: center;  
       background: none;
       font-family: Inter;
       font-style: normal;
       font-weight: 500;
       font-size: 16px;
       line-height: 24px;
       color: #17CF63;
    }
    .searchCalendar .react-calendar__tile--active.react-calendar__tile--rangeStart abbr {
        border-radius: 16px;
        margin: 0;
        width: 100%;
    }
   

}


